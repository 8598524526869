@import url(https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container-show{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 300px;
  margin: 0 auto;
}
.container-show .ra-field{
  max-width: 300px;
}
.tabs-form{

}
 .tabs-form-shedule{
  width: 100px;
  height: 100px;

}
.tab-form{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch; 
    margin: 0 auto;    
}
.MuiFormControl-marginDense{
  width: 100% !important;
}
.tabs-form .tab-form-1{

}
.tabs-form .tab-form-2{

}
.player_name { font-family: 'Rubik', sans-serif; }
.player_number {  font-family: 'Rubik Mono One', sans-serif; font-size: 32px; }
.player_position_age {      font-family: 'Ropa Sans', sans-serif; font-size: 22px; text-align: center; }

.stats-btn {      font-family: 'Ropa Sans', sans-serif; font-size: 23px; cursor: pointer;}
.stats-bt:hover .push-btn  { fill: #4390a3;}
.stats-bt:active .push-btn { fill: #185261;}
.stats-bt:hover .stats-btn { fill:#43434e}
.stats-bt:active .stats-btn { fill:#333363}


.stat_name {  font-family: 'Ropa Sans', sans-serif; font-size: 23px; fill: #61c2fb;}
.show_decription {fill: #3147c4;} 
.score_name{font-family: 'Ropa Sans', sans-serif; font-size: 23px; fill: #3147c4;}
.playercard{
  position: relative;
  width: 244px;
  height: 408px;
  margin-bottom: 1em;
}
.playercard .back_playercard, .playercard .front_playercard{
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}
.playercard .back_playercard{
  position: absolute;
  transform: rotateY(180deg);
  opacity: 0;
}
.playercard .front_playercard{
  position: absolute;
  opacity: 1;
} 

.iterator-v1 section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.footballer-show-layout{
  }


